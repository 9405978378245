import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/BAKAVE_d5r4">
    <SEO title="Failure and Faithfulness - Counter Culture" />
  </Layout>
)

export default SermonPost
